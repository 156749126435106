import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IHttpClient } from '@wix/fe-essentials/http-client';

import {
  list,
  addReaction,
  pinItem,
  unpinItem,
  removeReaction,
  subscribeToItem,
  unsubscribeFromItem,
} from '../../api/group-feed.api';

import { IStore } from '../types';

type ReactParams = {
  feedItemId: string;
  code: string;
};

export const subscribe = createAsyncThunk(
  'feed:subscribe',
  function (feedItemId: string, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;
    const { feed } = thunkAPI.getState() as IStore;

    return httpClient.request(subscribeToItem(feed.groupId!, feedItemId));
  },
);

export const unsubscribe = createAsyncThunk(
  'feed:unsubscribe',
  function (feedItemId: string, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;
    const { feed } = thunkAPI.getState() as IStore;

    return httpClient.request(unsubscribeFromItem(feed.groupId!, feedItemId));
  },
);

export const pin = createAsyncThunk(
  'feed:pin',
  function (feedItemId: string, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;
    const { feed } = thunkAPI.getState() as IStore;

    return httpClient.request(pinItem(feed.groupId!, feedItemId));
  },
);

export const unpin = createAsyncThunk(
  'feed:unpin',
  function (feedItemId: string, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;
    const { feed } = thunkAPI.getState() as IStore;

    return httpClient.request(unpinItem(feed.groupId!, feedItemId));
  },
);

export const react = createAsyncThunk(
  'feed:react',
  function (params: ReactParams, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;
    const { feed } = thunkAPI.getState() as IStore;

    return httpClient.request(
      addReaction(feed.groupId!, params.feedItemId, params.code),
    );
  },
);

export const unreact = createAsyncThunk(
  'feed:unreact',
  function (params: ReactParams, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;
    const { feed } = thunkAPI.getState() as IStore;

    return httpClient.request(
      removeReaction(feed.groupId!, params.feedItemId, params.code),
    );
  },
);

type FetchParams = {
  groupId: string;
  limit?: number;
};

export const fetch = createAsyncThunk(
  'feed:list',
  function (params: FetchParams, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;

    return httpClient.request(
      list(params.groupId, {
        cursor: {
          limit: params.limit,
        },
      }),
    );
  },
);

export const fetchNext = createAsyncThunk(
  'feed:list-more',
  function (limit: number = 10, thunkAPI) {
    const httpClient = thunkAPI.extra as IHttpClient;
    const { feed } = thunkAPI.getState() as IStore;

    return httpClient.request(
      list(feed.groupId!, {
        cursor: {
          cursor: feed.nextCursor,
          limit,
        },
      }),
    );
  },
);
